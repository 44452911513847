<template>
    <div :id="isModal ? '' : 'contents'" class="contents aff_cards_page">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <div class="page_ttl_wrap has_el">
                <h2 class="page_ttl">제휴카드</h2>
                <div class="btn_group">
                    <!-- Dev: 변경사항이 없을 경우 비활성화, 있을 경우 disabled 삭제 -->
                    <button type="button" class="btn strong2 w_m h_m" @click="updating()" disabled>발행하기</button>
                </div>
                <!-- Dev: 변경사항이 있을 경우에만 is_updated 클래스 추가 -->
                <div class="el_wrap taR">
                    <p class="txt_info">최근 발행일시 <span>YY.MM.DD hh:mm:ss</span></p>
                    <!-- Dev: is_updated 클래스 추가될 경우 아래 텍스트 보이도록 css 처리 완료 -->
                    <p class="txt_info updated">리스트에 변경사항이 있습니다.</p>
                </div>
            </div>
            <!-- e: 페이지 타이틀 -->
        </div>

        <!-- s: content_body -->
        <div class="content_body">
            <Pagination :paginationClass="'board_sec'" :pageData="orderedList">
                <template #table="tableData">
                    <!-- s: table -->
                    <!-- 게시글 있는 경우 (10개씩 노출) -->
                    <div class="tbl_wrap list">
                        <div class="tbl_head">
                            <div class="btn_group taR">
                                <button type="button" class="btn basic h_s w_sm" @click="confirmDelete()">삭제</button>
                                <button type="button" class="btn strong h_s w_sm"
                                        @click="redirect('/subMenu/cards/create')">카드 추가
                                </button>
                            </div>
                        </div>
                        <div class="tbl_div">
                            <div class="tbl_row_group head">
                                <div class="tbl_row">
                                    <div class="check">
                                        <div class="input_area checkbox">
                                            <div class="input_box">
                                                <label>
                                                    <input type="checkbox" v-model="selectAll" ref="selectAll"
                                                           @change="checkAll($event)">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="num"><p>카드 번호</p></div>
                                    <div class="title"><p>카드명</p></div>
                                    <div class="date"><p>등록일시</p></div>
                                    <div class="date"><p>최종 수정일시</p></div>
                                </div>
                            </div>
                            <Container class="tbl_row_group body"
                                       orientation="vertical"
                                       tag="div"
                                       :drop-placeholder="dropPlaceholderOptions"
                                       @drop="onDrop">
                                <Draggable class="tbl_row" v-for="(item, index) in cardsData" tag="div">
                                    <div class="check">
                                        <div class="input_area checkbox">
                                            <div class="input_box">
                                                <label>
                                                    <input type="checkbox" :value="item.hash"
                                                           @change="checkboxChanged($event)">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="num">
                                        <span>{{index + 1}}</span>
                                    </div>
                                    <div class="title" @click="redirect('/subMenu/cards/create')">
                    <span>
                      <a href="javascript:;">{{ item.name }}</a>
                    </span>
                                    </div>
                                    <div class="date">
                                        <p>
                                            <span>{{ item.registeredDate }}</span><span>{{ item.registeredTime }}</span>
                                        </p>
                                    </div>
                                    <div class="date">
                                        <p>
                                            <span>{{ item.modifiedDate }}</span><span>{{ item.modifiedTime }}</span>
                                        </p>
                                    </div>
                                </Draggable>
                            </Container>
                        </div>
                        <!-- 게시글 없는 경우
                        <div class="no_result">
                          <p>{{noListMessage}}</p>
                        </div>
                        게시글 없는 경우 -->
                    </div>
                    <!-- e: table -->
                </template>
            </Pagination>
            <!-- e: board_sec -->
        </div>

        <!-- e: content_body -->
    </div>

    <!-- s: 삭제 확인 Dialog -->
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            v-on:yesDialog="removeCompleted()">
        <template #body>
            <h3>삭제 확인</h3>
            <p>선택한 카드를 삭제하시겠습니까? <br/>변경된 내용은 "발행하기"를 진행해야 체험존에 반영됩니다.</p>
        </template>
    </Dialog>
    <!-- e: 삭제 확인 Dialog -->

    <!-- s: 페이지 나가기, 새로고침 확인 Dialog -->
    <Dialog :isOpen="cancelDialogState" :yes="'확인'" :cancel="'취소'" v-on:cancelDialog="cancelDialogState = false"
            v-on:yesDialog="cancelDialogState = false">
        <template #body>
            <h3>페이지에서 나가시겠습니다?</h3>
            <p>변경된 내용은 "발행하기"를 진행해야 체험존에 반영됩니다.</p>
        </template>
    </Dialog>
    <!-- e: 페이지 나가기, 새로고침 확인 Dialog -->

</template>

<script>
import Dialog from "@/components/dialog";
import Datepicker from 'vue3-datepicker';
import Pagination from '@/components/pagination';
import ImageView from '@/components/imageView';
import {Container, Draggable} from "vue3-smooth-dnd";
import {applyDrag} from "@/plugins/dnd-helpers";

export default {
    name: "index",
    layout: {
        name: 'admin',
    },
    components: {
        Pagination: Pagination,
        ImageView: ImageView,
        Datepicker: Datepicker,
        Dialog,
        Container: Container,
        Draggable: Draggable,
    },
    props: {
        isModal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        orderedList(){
            return this.cardsData.sort((a, b) => a.order - b.order);
        },
    },
    data() {
        return {
            noListMessage: '등록된 게시글이 없습니다',
            deleteDialogState: false,
            cancelDialogState: false,
            cardsData: [
                {
                    hash: 'check1',
                    name: '현대카드',
                    registeredDate: '2021-09-23',
                    registeredTime: '19:01:36',
                    modifiedDate: '2021-09-25',
                    modifiedTime: '19:01:36',
                    order: 1,
                },
                {
                    hash: 'check2',
                    name: '삼성카드',
                    registeredDate: '2021-09-23',
                    registeredTime: '19:01:36',
                    modifiedDate: '2021-09-25',
                    modifiedTime: '19:01:36',
                    order: 2,
                },
                {
                    hash: 'check3',
                    name: '신한카드',
                    registeredDate: '2021-09-23',
                    registeredTime: '19:01:36',
                    modifiedDate: '2021-09-25',
                    modifiedTime: '19:01:36',
                    order: 3,
                },
                {
                    hash: 'check4',
                    name: 'NH카드',
                    registeredDate: '2021-09-23',
                    registeredTime: '19:01:36',
                    modifiedDate: '2021-09-25',
                    modifiedTime: '19:01:36',
                    order: 4,
                }
            ],
            checkedLength: 0,
            mainVideoModal: false,
            dropPlaceholderOptions: {
                className: 'drop-preview',
                animationDuration: '150',
                showOnTop: true
            },
        }
    },
    methods: {
        confirmDelete() {
            this.deleteDialogState = true;
        },
        checkboxChanged(e) {
            if (e.target.checked) {
                this.checkedLength += 1;
            } else {
                this.checkedLength -= 1;
            }
            if (this.cardsData.length <= this.checkedLength) {
                this.$refs['selectAll'].checked = true;
            } else {
                this.$refs['selectAll'].checked = false;
            }
        },
        checkAll(e) {
            var checkbox = document.querySelectorAll('td input[type=checkbox]');
            if (e.target.checked) {
                for (var i = 0; i < checkbox.length; i++) {
                    if (!checkbox[i].checked) {
                        checkbox[i].checked = true;
                        this.checkedLength += 1;
                    }
                }
            } else {
                for (var i = 0; i < checkbox.length; i++) {
                    if (checkbox[i].checked) {
                        checkbox[i].checked = false;
                        this.checkedLength -= 1;
                    }
                }
            }
        },
        removeCompleted() {
            this.deleteDialogState = false;
            this.toastSuccess('카드가 삭제되었습니다.');
        },
        updating() {
            this.toastSuccess('제휴카드 업데이트가 진행중입니다.');
        },
        dragTable(data) {
            //변경 api
        },
        onDrop(dropResult) {
            if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
                this.cardsData[dropResult.removedIndex].order = dropResult.addedIndex + 1;
                this.cardsData[dropResult.addedIndex].order = dropResult.removedIndex + 1;
            }
            this.cardsData = applyDrag(this.cardsData, dropResult).sort((a, b) => {
                return a.order - b.order;
            });
        },
    }
}
</script>

<style scoped>

</style>